const subnavigation = document.querySelector('.page--subnavigation')

if (subnavigation) {

    let checkNavPosition = () => {
        const viewportHeight = window.innerHeight
        // get bounding client rect from element
        const navRect = subnavigation.getBoundingClientRect();
        // grab measurements and percentage conversion
        const positionTop = navRect.top/viewportHeight;

        // add active
        let add = () => {
            subnavigation.classList.add('top')
            document.querySelectorAll('nav.navigation--header li.navigation--listitem a.mark').forEach(mark => {
                mark.closest('.navigation--listitem').style.overflowY = 'hidden'
            })
        }
        // remove active
        let remove = () => {
            subnavigation.classList.remove('top')
            document.querySelectorAll('nav.navigation--header li.navigation--listitem a.mark').forEach(mark => {
                mark.closest('.navigation--listitem').style.overflowY = 'unset'
            })
        }

        if (navRect.top <= 85 && window.innerWidth < 576) {
            add();
        }
        if (navRect.top > 85 && window.innerWidth < 576) {
            remove();
        }
        if (navRect.top <= 91 && window.innerWidth >= 576) {
            add();
        }
        if (navRect.top > 91 && window.innerWidth >= 576) {
            remove();
        }
    }

    window.addEventListener('load', () => {
        checkNavPosition();
    });

    window.addEventListener('scroll', () => {
        checkNavPosition();
    });

    window.addEventListener('resize', () => {
        checkNavPosition();
    });

    window.addEventListener('orientationchange', () => {
        checkNavPosition();
    });


    subnavigation.querySelectorAll('.subnavigation--anchor').forEach(item => {
        linkID = item.getAttribute('href')

        if (linkID != null && linkID.includes('#')) {
            const ID = linkID.match(/#(.*)/)[1];
            const section = document.getElementById(`${ID}`)

            if (section) {
                // Function to check target element's position
                let checkTargetPosition = () => {
                    const viewportHeight = window.innerHeight
                    // get bounding client rect from element
                    const rect = section.getBoundingClientRect();
                    // grab measurements and percentage conversion
                    const positionTop = rect.top/viewportHeight;
                    const positionBottom = rect.bottom/viewportHeight;

                    // add active
                    let navigationAdd = () => {
                        item.classList.add('active')
                    }
                    // remove active
                    let navigationRemove = () => {
                        item.classList.remove('active')
                    }

                    if (rect.top <= 0 && rect.bottom >= 0) {
                        navigationAdd();
                    }

                    if (positionTop >= 0 && positionTop <= 0.3) {
                        navigationAdd();
                    }

                    if (positionTop >= 0.3) {
                        navigationRemove();
                    }

                    if (positionBottom >= 0.3 && positionBottom <= 0.35) {
                        navigationAdd();
                    }

                    if (positionBottom <= 0.3) {
                        navigationRemove();
                    }
                }

                window.addEventListener('load', () => {
                    checkTargetPosition();
                });

                window.addEventListener('scroll', () => {
                    checkTargetPosition();
                });

                window.addEventListener('resize', () => {
                    checkTargetPosition();
                });

                window.addEventListener('orientationchange', () => {
                    checkTargetPosition();
                });
            }
        }
    })
}
