window.addEventListener('load', () => {
    document.querySelectorAll('.text--expandable').forEach(item => {
        item.querySelector('button').addEventListener('click', () => {
            //Get Container
            const container = item.querySelector('.container')
            const containerInner = container.querySelector('.paragraph')
            //Get Container Height by PageLoad
            const containerInnerHeight = window.getComputedStyle(containerInner, null).getPropertyValue('height')

            item.classList.toggle('active')

            if (item.classList.contains('active')) {
                container.style.height = containerInnerHeight
                item.querySelector('button').innerHTML = 'Weniger anzeigen'
            }

            if (!item.classList.contains('active')) {
                container.style.height = 0
                item.querySelector('button').innerHTML = 'Mehr anzeigen'
            }
        })
    })

    window.addEventListener('resize', () => {
        document.querySelectorAll('.text--expandable').forEach(item => {
            //Get Container
            const container = item.querySelector('.container')
            const containerInner = container.querySelector('.paragraph')
            //Get Container Height by PageLoad
            const containerInnerHeight = window.getComputedStyle(containerInner, null).getPropertyValue('height')

            if (item.classList.contains('active')) {
                container.style.height = containerInnerHeight
            }
        })
    });
});
