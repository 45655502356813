// import JS Swiper + modules
import Swiper, {Pagination, Autoplay} from 'swiper';

if(document.querySelector('body.neos-backend')) {
    const swiperHero = new Swiper(".hero--swiper", {
        modules: [Pagination, Autoplay],
        allowTouchMove: false,
        loop: true,
        autoplay: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
} else {
    const swiperHero = new Swiper(".hero--swiper", {
        modules: [Pagination, Autoplay],
        allowTouchMove: false,
        speed: 500,
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
}
