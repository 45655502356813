//!!!Build Form!!!//
// Build Select-Dropdown
document.querySelectorAll('form select').forEach(item => {
    const select = item
    const selectName = select.getAttribute('name')
    const selectID = select.getAttribute('id')
    const label = select.parentElement.parentElement.querySelector('label').innerText;
    const options = select.querySelectorAll('option'); //options can contain more than one element

    //Taking all options right away into a string
    let optionContent = ''
    options.forEach(item => {
        optionContent += `<div class="selectOption" data-value="${item.value}">${item.innerText}</div>`
    });

    select.parentElement.innerHTML = `
        <div class="selectContainer">
            <input class="selectInput" id="${selectID}" name="${selectName}" type="hidden" value="" />
            <div class="currentOption">
                ${label}
            </div>
            <div class="selectContainerInner">${optionContent}</div>
        </div>
    `
});

//Setting Extra-Class for checkbox-input
document.querySelectorAll('input[type*="checkbox"]').forEach(item => {
    item.closest('.clearfix').classList.add('checkbox-input')
});

// Change Button-Text
document.querySelectorAll('.actions li.submit button').forEach(item => {
    item.innerText = 'Kontaktdaten jetzt senden'
    item.setAttribute("role", "button")
    item.setAttribute("aria-label", "Formular senden")
});


//!!!Control Form!!!//
// Toggle SelectContainer
const selectContainers = document.querySelectorAll('.selectContainer')
selectContainers.forEach(item => {
    item.addEventListener('click', event => {

        const currentContainer = event.target

        // remove active class for all select containers, but not for the current clicked select container
        removeActive(selectContainers, currentContainer)

        // set active class for clicked select container
        item.classList.toggle('active')

    })
})

const removeActive = (selects, currentSelect) => {
    selects.forEach(item => {
        if (item !== currentSelect.closest('.selectContainer')) {
            item.classList.remove('active')
        }
    })
}

// Choose Select option
const selectOptions = document.querySelectorAll('.selectOption')
selectOptions.forEach(item => {
    item.addEventListener('click', event => {
        const option = event.target
        const label = option.textContent
        const value = option.dataset.value
        const currentOption = option.closest('.selectContainer').querySelector('.currentOption')
        const input = option.closest('.selectContainer').querySelector('.selectInput')

        currentOption.textContent = label
        input.value = value
    })
})
