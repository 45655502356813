if(!document.querySelector('body.neos-backend')) {
    window.addEventListener('load', () => {
        document.querySelectorAll('.accordion--item').forEach(item => {
            item.querySelector('button').addEventListener('click', () => {
                //Get Container
                const container = item.closest('.accordion--item').querySelector('.container')
                const containerInner = container.querySelector('.container--inner')
                //Get Container Height by PageLoad
                const containerInnerHeight = window.getComputedStyle(containerInner, null).getPropertyValue('height')

                item.classList.toggle('active')

                if (item.classList.contains('active')) {
                    container.style.height = containerInnerHeight
                }

                if (!item.classList.contains('active')) {
                    container.style.height = 0
                }

                //Close other Accordion-Items in same Accordion-Container
                item.closest('.accordion').querySelectorAll('.accordion--item').forEach(otherItem => {
                    if (otherItem !== item) {
                        otherItem.classList.remove('active')
                        otherItem.querySelector('.container').style.height = 0
                    }
                })
            })
        })

        window.addEventListener('resize', () => {
            document.querySelectorAll('.accordion--item').forEach(item => {
                //Get Container
                const container = item.querySelector('.container')
                const containerInner = container.querySelector('.container--inner')
                //Get Container Height by PageLoad
                const containerInnerHeight = window.getComputedStyle(containerInner, null).getPropertyValue('height')

                if (item.classList.contains('active')) {
                    container.style.height = containerInnerHeight
                }
            })
        });
    });
}
