import Swiper, {Navigation, Pagination} from 'swiper';

const swiperTabs = new Swiper(".tabs--swiper", {
    modules: [Navigation, Pagination],
    slidesPerView: "auto",
    allowTouchMove: true,
    navigation: {
      nextEl: '.tabs-button-next',
      prevEl: '.tabs-button-prev',
    },
    pagination: {
      el: ".tabs-pagination",
      clickable: true,
    },
});

document.querySelectorAll('.tabs').forEach(tabs => {
    const tabList = tabs.querySelector('.tablist')
    const tabContainer = tabs.querySelector('.container--tabs')

    if (!tabs.classList.contains('backend')) {
        tabList.innerHTML = [
          ...tabContainer.querySelectorAll('.tab--label'),
          '<button class="tab--label swiper-slide placeholder--1" role="button" aria-label="Placeholder"></button>',
          '<button class="tab--label swiper-slide placeholder--2" role="button" aria-label="Placeholder"></button>',
        ]
        .map((tabLabel) => {
            if (tabLabel instanceof HTMLElement) {
                tabLabel.remove();
                return tabLabel.outerHTML;
            }
            return tabLabel;
        })
        .join('');

        tabList.querySelectorAll('.tab--label').forEach((label) => {
          onClassChange(label, changeClass);
        });
      }

    function onClassChange(node, callback) {
        let lastClassString = node.classList.toString();

        const mutationObserver = new MutationObserver((mutationList) => {
          for (const item of mutationList) {
            if (item.attributeName === "class") {
              const classString = node.classList.toString();
              if (classString !== lastClassString) {
                callback(mutationObserver);
                lastClassString = classString;
                break;
              }
            }
          }
        });

        mutationObserver.observe(node, { attributes: true });
        return mutationObserver;
    }

    function changeClass() {
        const activeSlideData = tabList.querySelector('.swiper-slide-active').querySelector('button').getAttribute('data')

        tabContainer.querySelectorAll('.tab--panel').forEach(panel => {
            panel.classList.remove('open')
            const panelData = panel.getAttribute('data')

            if(panelData == activeSlideData) {
                panel.classList.add('open')
            }

        })
    }

    const slides = document.querySelectorAll('.tab--label');
    slides.forEach((slide, index) => {
        slide.addEventListener('click', () => {
            if (!slide.classList.contains('swiper-slide-active')) {
                swiperTabs.slideTo(index);
            }
        });
    });
})
