import Swiper, {Navigation, Pagination} from 'swiper';

if(document.querySelector('body.neos-backend')) {
    // !!! Backend-Instanz
    const swiperTile = new Swiper(".tile--swiper", {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
        allowTouchMove: false,
        navigation: {
          nextEl: '.tile--slider-button-next',
          prevEl: '.tile--slider-button-prev',
        },
        pagination: {
          el: ".tile--slider-pagination",
          clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
} else {
    // !!! Live-Instanz
    const swiperTile = new Swiper(".tile--swiper", {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
        allowTouchMove: true,
        navigation: {
          nextEl: '.tile--slider-button-next',
          prevEl: '.tile--slider-button-prev',
        },
        pagination: {
          el: ".tile--slider-pagination",
          clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
}

